import { render, staticRenderFns } from "./SlideCard.vue?vue&type=template&id=bb811f00&scoped=true"
import script from "./SlideCard.vue?vue&type=script&lang=js"
export * from "./SlideCard.vue?vue&type=script&lang=js"
import style0 from "./SlideCard.vue?vue&type=style&index=0&id=bb811f00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb811f00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MpLink: require('/usr/src/app/components/mp-link/MpLink.vue').default,Headline: require('/usr/src/app/components/headline/Headline.vue').default,ResponsiveImage: require('/usr/src/app/components/responsive-image/ResponsiveImage.vue').default,Button: require('/usr/src/app/components/button/Button.vue').default})
