

export default {
  name: 'SlideCard',
  props: {
    /* GENERAL */
    slide: {
      type: Object,
      required: true
    },
    borderRadius: {
      type: String,
      default: null
    },
    aspectRatioDesktop: {
      type: String,
      default: null
    },
    aspectRatioMobile: {
      type: String,
      default: null
    },
    headline: {
      type: Array,
      required: true
    },
    /* DESKTOP */
    buttontext: {
      type: String,
      default: ''
    },
    buttonFillColor: {
      type: String,
      default: ''
    },
    buttonLabelColor: {
      type: String,
      default: ''
    }
  },
  computed: {

    cssVars () {
      return {
        '--border-radius': this.borderRadius,
        '--aspect-ratio-mobile': this.aspectRatioMobile || '4/5',
        '--aspect-ratio-desktop': this.aspectRatioDesktop || '4/5',
      }
    }
  },
}

